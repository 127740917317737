import plugin_vue3_A0OWXRrUgq from "/builds/penta/alboneon/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "/builds/penta/alboneon/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/penta/alboneon/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/builds/penta/alboneon/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/penta/alboneon/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/builds/penta/alboneon/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/penta/alboneon/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import fontawesome_CH30yp3mE1 from "/builds/penta/alboneon/src/plugins/fontawesome.ts";
import sentry_h0fMjCG9AB from "/builds/penta/alboneon/src/plugins/sentry.ts";
import vuetify_F4zXYcNIwC from "/builds/penta/alboneon/src/plugins/vuetify.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  fontawesome_CH30yp3mE1,
  sentry_h0fMjCG9AB,
  vuetify_F4zXYcNIwC
]