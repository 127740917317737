import validate from "/builds/penta/alboneon/node_modules/nuxt/dist/pages/runtime/validate.js";
export const globalMiddleware = [
  validate
]
export const namedMiddleware = {
  "inventories-middleware": () => import("/builds/penta/alboneon/src/middleware/InventoriesMiddleware.ts"),
  "inventory-redirect": () => import("/builds/penta/alboneon/src/middleware/InventoryRedirect.ts"),
  "journal-entry-exists": () => import("/builds/penta/alboneon/src/middleware/JournalEntryExists.ts"),
  "update-party-last-visited": () => import("/builds/penta/alboneon/src/middleware/UpdatePartyLastVisited.ts"),
  characters: () => import("/builds/penta/alboneon/src/middleware/characters.ts")
}