import { default as _91buff_93QuXATrOtcRMeta } from "/builds/penta/alboneon/src/pages/dice/buffs/[buff].vue?macro=true";
import { default as indexMvwaIyv2tmMeta } from "/builds/penta/alboneon/src/pages/dice/buffs/index.vue?macro=true";
import { default as buffsgBzmPkzkylMeta } from "/builds/penta/alboneon/src/pages/dice/buffs.vue?macro=true";
import { default as index6zjBhxNKkSMeta } from "/builds/penta/alboneon/src/pages/dice/index.vue?macro=true";
import { default as _91saved_93tAn2vPbVhaMeta } from "/builds/penta/alboneon/src/pages/dice/saved/[saved].vue?macro=true";
import { default as indexIlUZUu9fwWMeta } from "/builds/penta/alboneon/src/pages/dice/saved/index.vue?macro=true";
import { default as savedT8pl2ZZF9RMeta } from "/builds/penta/alboneon/src/pages/dice/saved.vue?macro=true";
import { default as dice29wnyBxu33Meta } from "/builds/penta/alboneon/src/pages/dice.vue?macro=true";
import { default as indexPaEdU3X29KMeta } from "/builds/penta/alboneon/src/pages/index.vue?macro=true";
import { default as _91character_93fRKwKPQeydMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/characters/[character].vue?macro=true";
import { default as newofIYcXOezJMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/characters/new.vue?macro=true";
import { default as characters3DfRcC6EE7Meta } from "/builds/penta/alboneon/src/pages/party/[uuid]/characters.vue?macro=true";
import { default as indexcnIzFUr051Meta } from "/builds/penta/alboneon/src/pages/party/[uuid]/index.vue?macro=true";
import { default as _91inventory_93bTz7ZNiy5AMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/inventories/[inventory].vue?macro=true";
import { default as templates3TqsJ7lRKWMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/inventories/templates.vue?macro=true";
import { default as inventories4roSj5KwFQMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/inventories.vue?macro=true";
import { default as inventory5pJ65rYdZOMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/inventory.vue?macro=true";
import { default as _91journal_93k8m7h3xfFeMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/journal/[journal].vue?macro=true";
import { default as _91journal_93njmZ2bOEwoMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/journal/edit/[journal].vue?macro=true";
import { default as new7VRoIeDPPxMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/journal/new.vue?macro=true";
import { default as journalyLlj3iuR9lMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/journal.vue?macro=true";
import { default as deadkKJ9hUzyQdMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/npcs/dead.vue?macro=true";
import { default as indexpNbsdtu5qaMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/npcs/index.vue?macro=true";
import { default as npcsBDeMeDPv9tMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/npcs.vue?macro=true";
import { default as questsZc1kt3M17iMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/quests.vue?macro=true";
import { default as indexy5IyPiT4iDMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/settings/index.vue?macro=true";
import { default as inventoryYS6VF7SjomMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/settings/inventory.vue?macro=true";
import { default as modulesGCDAOAzbu1Meta } from "/builds/penta/alboneon/src/pages/party/[uuid]/settings/modules.vue?macro=true";
import { default as settingsrA8t0r4C1aMeta } from "/builds/penta/alboneon/src/pages/party/[uuid]/settings.vue?macro=true";
import { default as _91uuid_93JgHRdets55Meta } from "/builds/penta/alboneon/src/pages/party/[uuid].vue?macro=true";
import { default as indexxHnTu4jAgdMeta } from "/builds/penta/alboneon/src/pages/party/index.vue?macro=true";
export default [
  {
    path: dice29wnyBxu33Meta?.path ?? "/dice",
    children: [
  {
    path: buffsgBzmPkzkylMeta?.path ?? "buffs",
    children: [
  {
    name: _91buff_93QuXATrOtcRMeta?.name ?? "dice-buffs-buff",
    path: _91buff_93QuXATrOtcRMeta?.path ?? ":buff()",
    meta: _91buff_93QuXATrOtcRMeta || {},
    alias: _91buff_93QuXATrOtcRMeta?.alias || [],
    redirect: _91buff_93QuXATrOtcRMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/dice/buffs/[buff].vue").then(m => m.default || m)
  },
  {
    name: indexMvwaIyv2tmMeta?.name ?? "dice-buffs",
    path: indexMvwaIyv2tmMeta?.path ?? "",
    meta: indexMvwaIyv2tmMeta || {},
    alias: indexMvwaIyv2tmMeta?.alias || [],
    redirect: indexMvwaIyv2tmMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/dice/buffs/index.vue").then(m => m.default || m)
  }
],
    name: buffsgBzmPkzkylMeta?.name ?? undefined,
    meta: buffsgBzmPkzkylMeta || {},
    alias: buffsgBzmPkzkylMeta?.alias || [],
    redirect: buffsgBzmPkzkylMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/dice/buffs.vue").then(m => m.default || m)
  },
  {
    name: index6zjBhxNKkSMeta?.name ?? "dice",
    path: index6zjBhxNKkSMeta?.path ?? "",
    meta: index6zjBhxNKkSMeta || {},
    alias: index6zjBhxNKkSMeta?.alias || [],
    redirect: index6zjBhxNKkSMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/dice/index.vue").then(m => m.default || m)
  },
  {
    path: savedT8pl2ZZF9RMeta?.path ?? "saved",
    children: [
  {
    name: _91saved_93tAn2vPbVhaMeta?.name ?? "dice-saved-saved",
    path: _91saved_93tAn2vPbVhaMeta?.path ?? ":saved()",
    meta: _91saved_93tAn2vPbVhaMeta || {},
    alias: _91saved_93tAn2vPbVhaMeta?.alias || [],
    redirect: _91saved_93tAn2vPbVhaMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/dice/saved/[saved].vue").then(m => m.default || m)
  },
  {
    name: indexIlUZUu9fwWMeta?.name ?? "dice-saved",
    path: indexIlUZUu9fwWMeta?.path ?? "",
    meta: indexIlUZUu9fwWMeta || {},
    alias: indexIlUZUu9fwWMeta?.alias || [],
    redirect: indexIlUZUu9fwWMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/dice/saved/index.vue").then(m => m.default || m)
  }
],
    name: savedT8pl2ZZF9RMeta?.name ?? undefined,
    meta: savedT8pl2ZZF9RMeta || {},
    alias: savedT8pl2ZZF9RMeta?.alias || [],
    redirect: savedT8pl2ZZF9RMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/dice/saved.vue").then(m => m.default || m)
  }
],
    name: dice29wnyBxu33Meta?.name ?? undefined,
    meta: dice29wnyBxu33Meta || {},
    alias: dice29wnyBxu33Meta?.alias || [],
    redirect: dice29wnyBxu33Meta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/dice.vue").then(m => m.default || m)
  },
  {
    name: indexPaEdU3X29KMeta?.name ?? "index",
    path: indexPaEdU3X29KMeta?.path ?? "/",
    meta: indexPaEdU3X29KMeta || {},
    alias: indexPaEdU3X29KMeta?.alias || [],
    redirect: indexPaEdU3X29KMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/index.vue").then(m => m.default || m)
  },
  {
    path: _91uuid_93JgHRdets55Meta?.path ?? "/party/:uuid()",
    children: [
  {
    name: characters3DfRcC6EE7Meta?.name ?? "party-uuid-characters",
    path: characters3DfRcC6EE7Meta?.path ?? "characters",
    children: [
  {
    name: _91character_93fRKwKPQeydMeta?.name ?? "party-uuid-characters-character",
    path: _91character_93fRKwKPQeydMeta?.path ?? ":character()",
    meta: _91character_93fRKwKPQeydMeta || {},
    alias: _91character_93fRKwKPQeydMeta?.alias || [],
    redirect: _91character_93fRKwKPQeydMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/characters/[character].vue").then(m => m.default || m)
  },
  {
    name: newofIYcXOezJMeta?.name ?? "party-uuid-characters-new",
    path: newofIYcXOezJMeta?.path ?? "new",
    meta: newofIYcXOezJMeta || {},
    alias: newofIYcXOezJMeta?.alias || [],
    redirect: newofIYcXOezJMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/characters/new.vue").then(m => m.default || m)
  }
],
    meta: characters3DfRcC6EE7Meta || {},
    alias: characters3DfRcC6EE7Meta?.alias || [],
    redirect: characters3DfRcC6EE7Meta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/characters.vue").then(m => m.default || m)
  },
  {
    name: indexcnIzFUr051Meta?.name ?? "party-uuid",
    path: indexcnIzFUr051Meta?.path ?? "",
    meta: indexcnIzFUr051Meta || {},
    alias: indexcnIzFUr051Meta?.alias || [],
    redirect: indexcnIzFUr051Meta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: inventories4roSj5KwFQMeta?.name ?? "party-uuid-inventories",
    path: inventories4roSj5KwFQMeta?.path ?? "inventories",
    children: [
  {
    name: _91inventory_93bTz7ZNiy5AMeta?.name ?? "party-uuid-inventories-inventory",
    path: _91inventory_93bTz7ZNiy5AMeta?.path ?? ":inventory()",
    meta: _91inventory_93bTz7ZNiy5AMeta || {},
    alias: _91inventory_93bTz7ZNiy5AMeta?.alias || [],
    redirect: _91inventory_93bTz7ZNiy5AMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/inventories/[inventory].vue").then(m => m.default || m)
  },
  {
    name: templates3TqsJ7lRKWMeta?.name ?? "party-uuid-inventories-templates",
    path: templates3TqsJ7lRKWMeta?.path ?? "templates",
    meta: templates3TqsJ7lRKWMeta || {},
    alias: templates3TqsJ7lRKWMeta?.alias || [],
    redirect: templates3TqsJ7lRKWMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/inventories/templates.vue").then(m => m.default || m)
  }
],
    meta: inventories4roSj5KwFQMeta || {},
    alias: inventories4roSj5KwFQMeta?.alias || [],
    redirect: inventories4roSj5KwFQMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/inventories.vue").then(m => m.default || m)
  },
  {
    name: inventory5pJ65rYdZOMeta?.name ?? "party-uuid-inventory",
    path: inventory5pJ65rYdZOMeta?.path ?? "inventory",
    meta: inventory5pJ65rYdZOMeta || {},
    alias: inventory5pJ65rYdZOMeta?.alias || [],
    redirect: inventory5pJ65rYdZOMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/inventory.vue").then(m => m.default || m)
  },
  {
    name: journalyLlj3iuR9lMeta?.name ?? "party-uuid-journal",
    path: journalyLlj3iuR9lMeta?.path ?? "journal",
    children: [
  {
    name: _91journal_93k8m7h3xfFeMeta?.name ?? "party-uuid-journal-journal",
    path: _91journal_93k8m7h3xfFeMeta?.path ?? ":journal()",
    meta: _91journal_93k8m7h3xfFeMeta || {},
    alias: _91journal_93k8m7h3xfFeMeta?.alias || [],
    redirect: _91journal_93k8m7h3xfFeMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/journal/[journal].vue").then(m => m.default || m)
  },
  {
    name: _91journal_93njmZ2bOEwoMeta?.name ?? "party-uuid-journal-edit-journal",
    path: _91journal_93njmZ2bOEwoMeta?.path ?? "edit/:journal()",
    meta: _91journal_93njmZ2bOEwoMeta || {},
    alias: _91journal_93njmZ2bOEwoMeta?.alias || [],
    redirect: _91journal_93njmZ2bOEwoMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/journal/edit/[journal].vue").then(m => m.default || m)
  },
  {
    name: new7VRoIeDPPxMeta?.name ?? "party-uuid-journal-new",
    path: new7VRoIeDPPxMeta?.path ?? "new",
    meta: new7VRoIeDPPxMeta || {},
    alias: new7VRoIeDPPxMeta?.alias || [],
    redirect: new7VRoIeDPPxMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/journal/new.vue").then(m => m.default || m)
  }
],
    meta: journalyLlj3iuR9lMeta || {},
    alias: journalyLlj3iuR9lMeta?.alias || [],
    redirect: journalyLlj3iuR9lMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/journal.vue").then(m => m.default || m)
  },
  {
    path: npcsBDeMeDPv9tMeta?.path ?? "npcs",
    children: [
  {
    name: deadkKJ9hUzyQdMeta?.name ?? "party-uuid-npcs-dead",
    path: deadkKJ9hUzyQdMeta?.path ?? "dead",
    meta: deadkKJ9hUzyQdMeta || {},
    alias: deadkKJ9hUzyQdMeta?.alias || [],
    redirect: deadkKJ9hUzyQdMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/npcs/dead.vue").then(m => m.default || m)
  },
  {
    name: indexpNbsdtu5qaMeta?.name ?? "party-uuid-npcs",
    path: indexpNbsdtu5qaMeta?.path ?? "",
    meta: indexpNbsdtu5qaMeta || {},
    alias: indexpNbsdtu5qaMeta?.alias || [],
    redirect: indexpNbsdtu5qaMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/npcs/index.vue").then(m => m.default || m)
  }
],
    name: npcsBDeMeDPv9tMeta?.name ?? undefined,
    meta: npcsBDeMeDPv9tMeta || {},
    alias: npcsBDeMeDPv9tMeta?.alias || [],
    redirect: npcsBDeMeDPv9tMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/npcs.vue").then(m => m.default || m)
  },
  {
    name: questsZc1kt3M17iMeta?.name ?? "party-uuid-quests",
    path: questsZc1kt3M17iMeta?.path ?? "quests",
    meta: questsZc1kt3M17iMeta || {},
    alias: questsZc1kt3M17iMeta?.alias || [],
    redirect: questsZc1kt3M17iMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/quests.vue").then(m => m.default || m)
  },
  {
    path: settingsrA8t0r4C1aMeta?.path ?? "settings",
    children: [
  {
    name: indexy5IyPiT4iDMeta?.name ?? "party-uuid-settings",
    path: indexy5IyPiT4iDMeta?.path ?? "",
    meta: indexy5IyPiT4iDMeta || {},
    alias: indexy5IyPiT4iDMeta?.alias || [],
    redirect: indexy5IyPiT4iDMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/settings/index.vue").then(m => m.default || m)
  },
  {
    name: inventoryYS6VF7SjomMeta?.name ?? "party-uuid-settings-inventory",
    path: inventoryYS6VF7SjomMeta?.path ?? "inventory",
    meta: inventoryYS6VF7SjomMeta || {},
    alias: inventoryYS6VF7SjomMeta?.alias || [],
    redirect: inventoryYS6VF7SjomMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/settings/inventory.vue").then(m => m.default || m)
  },
  {
    name: modulesGCDAOAzbu1Meta?.name ?? "party-uuid-settings-modules",
    path: modulesGCDAOAzbu1Meta?.path ?? "modules",
    meta: modulesGCDAOAzbu1Meta || {},
    alias: modulesGCDAOAzbu1Meta?.alias || [],
    redirect: modulesGCDAOAzbu1Meta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/settings/modules.vue").then(m => m.default || m)
  }
],
    name: settingsrA8t0r4C1aMeta?.name ?? undefined,
    meta: settingsrA8t0r4C1aMeta || {},
    alias: settingsrA8t0r4C1aMeta?.alias || [],
    redirect: settingsrA8t0r4C1aMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid]/settings.vue").then(m => m.default || m)
  }
],
    name: _91uuid_93JgHRdets55Meta?.name ?? undefined,
    meta: _91uuid_93JgHRdets55Meta || {},
    alias: _91uuid_93JgHRdets55Meta?.alias || [],
    redirect: _91uuid_93JgHRdets55Meta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexxHnTu4jAgdMeta?.name ?? "party",
    path: indexxHnTu4jAgdMeta?.path ?? "/party",
    meta: indexxHnTu4jAgdMeta || {},
    alias: indexxHnTu4jAgdMeta?.alias || [],
    redirect: indexxHnTu4jAgdMeta?.redirect || undefined,
    component: () => import("/builds/penta/alboneon/src/pages/party/index.vue").then(m => m.default || m)
  }
]