import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
  const release = 'alboneon@' + nuxtApp.$config.public.VERSION
  const environment = nuxtApp.$config.public.SENTRY_ENVIRONMENT
  const config = useRuntimeConfig()

  Sentry.init({
    dsn: config.public.SENTRY_FRONTEND_URL,
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    debug: config.public.version === 'development',
    release,
    environment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(
          nuxtApp.$router as Router
        ),
        tracePropagationTargets: [
          'localhost',
          'alboneon.xyz',
          'staging.alboneon.xyz',
          /^\//,
        ],
      }),
      new Sentry.Replay(),
    ],
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    sampleRate: 1,
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
  })

  return {
    provide: {
      sentry: () => Sentry,
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  }
})
